import ReactGA4 from "react-ga4";
import ReactGA, { EventArgs } from "react-ga";
import axios from "axios";
import Theme from "./interfaces/Theme";
import moment from "moment";

import * as _ from "lodash";
import { v4 } from "uuid";

import timezones from "./timezones.json";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const { REACT_APP_MORTGAGE_URL, REACT_APP_SHOP_URL } = process.env;

interface Event {
  category: string;
  action: string;
}

const sessionId = v4();

export const trackMPEvent = async (apiKey: string, data: any) => {
  await axios.post(`${REACT_APP_SHOP_URL}/api/v1/shop/client/analytics`, {
    sessionId,
    apiKey,
    pluginType: "parkerslide",
    ...data,
  });
};

const getTimeOfDay = (startTime: string) => {
  const time = parseInt(startTime);
  if (time >= 12) {
    return "PM";
  }
  return "AM";
};

export const getReadableDate = (date: string) => {
  const time = date.split("T")[1].split(":");
  return `${moment(date).utc().format("MMMM Do YYYY")} at ${time[0]}:${
    time[1]
  } ${getTimeOfDay(time[0])}`;
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const numberWithCommas = (number: string) => {
  if (number) {
    return `£ ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  return number;
};

export const isUniversalAnalytics = (googleAnalyticsTrackingId: string) => {
  return googleAnalyticsTrackingId.startsWith("UA-");
};

export const isGA4Analytics = (googleAnalyticsTrackingId: string) => {
  return googleAnalyticsTrackingId.startsWith("G-");
};

export const initializeUniversalAnalytics = (
  googleAnalyticsTrackingId: string
) => {
  ReactGA.initialize(googleAnalyticsTrackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const initializeGA4Analytics = (googleAnalyticsTrackingId: string) => {
  let gaOptions = {};

  if (sessionId) {
    gaOptions = {
      cookieDomain: "auto",
      clientId: sessionId,
    };
  }
  ReactGA4.initialize(googleAnalyticsTrackingId, {
    gaOptions,
  });
  ReactGA4.send("pageview");
};

export const initializeGoogleAnalytics = (
  googleAnalyticsTrackingId: string[]
) => {

  if (Array.isArray(googleAnalyticsTrackingId)) {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id)
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id)
    );

    if (uaIndex !== -1) {
      initializeUniversalAnalytics(googleAnalyticsTrackingId[uaIndex]);
    }

    if (ga4Index !== -1) {
      initializeGA4Analytics(googleAnalyticsTrackingId[ga4Index]);
    }
  }
};

export const trackEvent = (
  googleAnalyticsTrackingId: string[],
  event: Event
) => {
  if (Array.isArray(googleAnalyticsTrackingId)) {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id)
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id)
    );
    if (uaIndex !== -1) {
      ReactGA.event({
        ...event,
        nonInteraction: true,
      });
    }

    if (ga4Index !== -1) {
      ReactGA4.event({
        ...event,
        nonInteraction: true,
      });
    }
  }
};

export const sendNewLead = async (values: any, theme: Theme) => {
  const name = values?.applicant1_firstName
    ? `${values?.applicant1_firstName} ${values?.applicant1_lastName}`
    : "Lead";

  const companyLogo = theme?.logo;
  const formData = Object.keys(values)
    .map((key) => {
      if (
        key === "theme" ||
        key === "simplified" ||
        key === "templates" ||
        key === "property_filtered"
      ) {
        return null;
      }

      if (
        key === "mortgage_type" &&
        (theme.version === "customer-service-questionnaire" ||
          theme.version === "rightmove")
      ) {
        return null;
      }

      let refinedKey = key;
      if (key.indexOf("]_") > -1) {
        refinedKey = key.substring(key.indexOf("]_") + 2);
      }
      return {
        label: _.startCase(refinedKey),
        value: JSON.stringify(values[key]),
      };
    })
    .filter(Boolean);
  //
  await axios.post(`${REACT_APP_MORTGAGE_URL}/api/teams/newLead`, {
    apiKey: theme?.apiKey,
    props: {
      firstName: values?.applicant1_firstName,
      lastName: values?.applicant1_lastName,
      name,
      formData,
      companyLogo,
      companyName: theme?.customization?.companyName,
      botName: theme?.customization?.botName,
      theme: theme?.customization?.primaryColor,
    },
    emailCustomization: theme?.emailCustomization,
    links: theme.links,
    admin: theme?.email,
  });

  await trackMPEvent(theme?.apiKey!, {
    lead: values,
  });
};

export const sendEmail = async (values: any, theme: any) => {
  const name = values?.applicant1_firstName
    ? `${values?.applicant1_firstName} ${values?.applicant1_lastName}`
    : "Lead";
  const companyLogo = theme?.logo;
  const formData = Object.keys(values)
    .map((key) => {
      if (
        key === "theme" ||
        key === "simplified" ||
        key === "templates" ||
        key === "property_filtered" ||
        key === "addresses"
      ) {
        return null;
      }

      if (
        key === "mortgage_type" &&
        (theme.version === "customer-service-questionnaire" ||
          theme.version === "rightmove")
      ) {
        return null;
      }

      let refinedKey = key;
      if (key.indexOf("]_") > -1) {
        refinedKey = key.substring(key.indexOf("]_") + 2);
      }
      return {
        label: _.startCase(refinedKey),
        value: JSON.stringify(values[key]),
      };
    })
    .filter(Boolean);
  const customFormData = Object.keys(theme?.customData || {}).map((key) => {
    return {
      label: _.startCase(key),
      value: JSON.stringify(theme?.customData[key]),
    };
  });
  //
  await axios.post(`${REACT_APP_MORTGAGE_URL}/api/teams/sendEmail`, {
    apiKey: theme?.apiKey,
    props: {
      firstName: values?.applicant1_firstName,
      lastName: values?.applicant1_lastName,
      name,
      formData: formData.concat(customFormData),
      companyLogo,
      theme: theme?.customization?.primaryColor,
      companyName: theme?.customization?.companyName,
      botName: theme?.customization?.botName,
      subject: theme?.emailCustomization?.newLeadEmailSubject
        ? `${theme?.emailCustomization?.newLeadEmailSubject} - ${name}`
        : `New Lead - ${name}`,
    },
    email: values?.applicant1_email,
    emailCustomization: theme?.emailCustomization,
    appointmentCustomization: theme?.appointmentCustomization,
    admin: theme?.email,
    links: theme?.links,
    templateName: theme?.appointmentCustomization?.appointmentTemplate,
  });

  return formData;
};

const getEmailValue = (values: any) => {
  const arrayOfValues = Object.keys(values);
  const emailKey = arrayOfValues.find(
    (data: any) => data.toLowerCase().indexOf("email") > -1
  );
  return emailKey ? values[emailKey] : null;
};

const getNames = (values: any) => {
  const arrayOfValues = Object.keys(values);
  const _firstName: string | any = arrayOfValues.find(
    (data: any) => data.toLowerCase().indexOf("first_name") > -1
  );
  const _lastName: string | any = arrayOfValues.find(
    (data: any) => data.toLowerCase().indexOf("last_name") > -1
  );
  return _firstName || _lastName
    ? `${values[_firstName]} ${values[_lastName]}`
    : "";
};

export const sendAppointmentEmail = async (values: any, theme: any) => {
  const name = values?.applicant1_firstName
    ? `${values?.applicant1_firstName}`
    : getNames(values);
  const email = values?.applicant1_email
    ? values?.applicant1_email
    : getEmailValue(values);
  const companyLogo = theme?.logo;
  const date = values?.appointment_date?.split("T")[0];
  const time = values?.appointment_date?.split("T")[1]?.split(":");
  const startTime = `${date}T${time[0]}:00:00+0${isDaylightSavingTimeUK() ? 1 : 0}00`;
  const endTime = `${date}T${time[0]}:30:00+0${isDaylightSavingTimeUK() ? 1 : 0}00`;
  const _portalName = theme?.emailCustomization?.portalName || "";
  const formData = Object.keys(values)
    .map((key) => {
      if (
        key === "theme" ||
        key === "simplified" ||
        key === "templates" ||
        key === "property_filtered"
      ) {
        return null;
      }

      if (
        key === "mortgage_type" &&
        (theme.version === "customer-service-questionnaire" ||
          theme.version === "rightmove")
      ) {
        return null;
      }

      if (
        _portalName === "Fair Investment App" &&
        (key === "payment_method" ||
          key === "reasonForRemortgage" ||
          key === "product_type" ||
          key === "product_term" ||
          key === "mortgage_class" ||
          key === "monthly_rent")
      ) {
        return null;
      }

      let refinedKey = key;
      if (key.indexOf("]_") > -1) {
        refinedKey = key.substring(key.indexOf("]_") + 2);
      }
      return {
        label: _.startCase(refinedKey),
        value: JSON.stringify(values[key]),
      };
    })
    .filter(Boolean);

  if (
    values?.contact_requested === "Video Call" ||
    theme.version === "rightmove"
  ) {
    //trigger video call appointment
    await axios.post(
      `${REACT_APP_MORTGAGE_URL}/api/teams/scheduleAppointment`,
      {
        apiKey: theme?.apiKey,
        name,
        email,
        startTime,
        endTime,
        props: {
          firstName: values?.applicant1_firstName,
          lastName: values?.applicant1_lastName,
          startTime: getReadableDate(startTime),
          endTime: getReadableDate(endTime),
          email,
          name,
          formData,
          companyLogo,
          theme: theme?.customization?.primaryColor,
          botName: theme?.customization?.botName,
          companyName: theme?.customization?.companyName,
        },
        templateName:
          theme.version === "rightmove"
            ? "call-appointment"
            : theme?.appointmentCustomization?.appointmentTemplate
            ? theme?.appointmentCustomization?.appointmentTemplate
            : "video-appointment",
        emailCustomization: {
          ...theme?.emailCustomization,
          subject: theme?.emailCustomization?.subject || "Mortgage Appointment",
        },
        appointmentCustomization: theme?.appointmentCustomization,
        admin: theme?.email || "adrian@meetparker.co.uk",
        links: theme?.links,
      }
    );
  } else {
    const defaultTemplate =
      values?.contact_requested === "Telephone Call"
        ? "call-appointment"
        : "face-to-face-appointment";
    await axios.post(
      `${REACT_APP_MORTGAGE_URL}/api/teams/requestCallOrFaceToFaceAppointment`,
      {
        apiKey: theme?.apiKey,
        name,
        email,
        props: {
          firstName: values?.applicant1_firstName,
          lastName: values?.applicant1_lastName,
          startTime: getReadableDate(startTime),
          endTime: getReadableDate(endTime),
          formData,
          name,
          email,
          companyLogo,
          theme: theme?.customization?.primaryColor,
          botName: theme?.customization?.botName,
          companyName: theme?.customization?.companyName,
        },
        templateName: theme?.appointmentCustomization?.appointmentTemplate
          ? theme?.appointmentCustomization?.appointmentTemplate
          : defaultTemplate,
        emailCustomization: {
          ...theme?.emailCustomization,
          subject: theme?.emailCustomization?.subject || "Mortgage Appointment",
        },
        appointmentCustomization: theme?.appointmentCustomization,
        admin: theme?.email || "adrian@meetparker.co.uk",
        links: theme?.links,
      }
    );
  }

  await trackMPEvent(theme?.apiKey, {
    appointment: {
      type: values?.contact_requested,
      date: startTime,
    },
  });

  await trackEvent(theme?.googleAnalyticsTrackingId,  {
    category: "MeetParkerChat",
    action: "Book Appointment",
  })
};

export const isDaylightSavingTimeUK = () => {
  const now = new Date();
  const year = now.getFullYear();

  // Calculate the start and end dates of daylight saving time in the UK
  // Daylight saving time in the UK starts on the last Sunday of March at 1:00 AM
  const start = new Date(year, 2, 31 - (new Date(year, 2, 31).getDay() || 7), 1);
  // Daylight saving time in the UK ends on the last Sunday of October at 2:00 AM
  const end = new Date(year, 9, 31 - (new Date(year, 9, 31).getDay() || 7), 2);

  return now >= start && now < end;
}

export const getTimeDiff = (date1: Date, date2: Date) => {
  const start = date1.getTime();
  const end = date2.getTime();

  const diff = end - start;
  return Math.floor(diff / 1000);
};
//
export const getTimes = (theme: Theme, day = '') => {
  let times = [];
  let startTime = theme?.workHours?.startTime || "09:00";
  let endTime = theme?.workHours?.endTime || "16:00";

  if (theme?.workHours?.hours) {
    switch (day) {
      case 'monday':
        startTime = theme?.workHours?.hours?.monday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.monday?.endTime || endTime;
        break;
      case 'tuesday':
        startTime = theme?.workHours?.hours?.tuesday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.tuesday?.endTime || endTime;
        break;
      case 'wednesday':
        startTime = theme?.workHours?.hours?.wednesday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.wednesday?.endTime || endTime;
        break;
      case 'thursday':
        startTime = theme?.workHours?.hours?.thursday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.thursday?.endTime || endTime;
        break;
      case 'friday':
        startTime = theme?.workHours?.hours?.friday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.friday?.endTime || endTime;
        break;
      case 'saturday':
        startTime = theme?.workHours?.hours?.saturday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.saturday?.endTime || endTime;
        break;
      case 'sunday':
        startTime = theme?.workHours?.hours?.sunday?.startTime || startTime;
        endTime = theme?.workHours?.hours?.sunday?.endTime || endTime;
        break;
    
      default:
        break;
    }
  }
  const minutesInterval = 60 * (theme?.workHours?.timeInterval || 0.5);
  const today = new Date();
  let startDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDay(),
    parseInt(startTime.split(":")[0]),
    parseInt(startTime.split(":")[1])
  );
  let startDateCopy = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDay(),
    parseInt(startTime.split(":")[0]),
    parseInt(startTime.split(":")[1])
  );
  const endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDay(),
    parseInt(endTime.split(":")[0]),
    parseInt(endTime.split(":")[1])
  );

  while (getTimeDiff(startDate, endDate) >= 0) {
    startDateCopy.setMinutes(startDateCopy.getMinutes() + minutesInterval);
    times.push({
      label: `${startDate.getHours()}:${("0" + startDate.getMinutes()).slice(
        -2
      )}${startDate.getHours() < 12 ? "am" : "pm"}`,
      time: `${("0" + startDate.getHours()).slice(-2)}:${(
        "0" + startDate.getMinutes()
      ).slice(-2)}:00`,
      end: `${("0" + startDateCopy.getHours()).slice(-2)}:${(
        "0" + startDateCopy.getMinutes()
      ).slice(-2)}:00`,
    });
    startDate.setMinutes(startDate.getMinutes() + minutesInterval);
  }

  return times;
};

export const getTimezone = (value: string) => {
  return timezones.find((timezone: any) => timezone.value === value);
};

export const trackGAEvent = (
  googleAnalyticsTrackingId: string[],
  event: EventArgs
) => {
  if (Array.isArray(googleAnalyticsTrackingId)) {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id)
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id)
    );

    if (uaIndex !== -1) {
      ReactGA.event({
        ...event,
        nonInteraction: true,
      });
    }

    if (ga4Index !== -1) {
      ReactGA4.event({
        ...event,
        nonInteraction: true,
      });
    }
  }
};