import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";

import { Formik, FormikHelpers } from "formik";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import _ from 'lodash';

import axios from "axios";

import CalendarIcon from "../../assets/icons/calendar.svg";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import ClockIcon from "../../assets/icons/clock.svg";
import WorldIcon from "../../assets/icons/world.svg";

import timezones from "./timezones.json";

import { dayNames, getTimes, months, trackGAEvent } from "../../util";

import { trackPluginEvent } from "../../services/analytics/analytics";
import "./index.scss";

interface DateItem {
    day: number;
    date: number;
    fullDate: Date | null;
    year: number;
    month: number;
}

interface TimeObject {
    label?: string;
    time?: string;
    end?: string;
}

export interface DateObject {
    startTime: string;
    endTime: string;
}

interface CalendarConfirmation {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    notes?: string;
}

interface CalendarEvent {
    subject: string;
    startTime: string;
    endTime: string;
}

const { REACT_APP_MORTGAGE_URL } = process.env;

const Calendar = () => {
    const theme: any = useTheme();

    const times = getTimes(theme?.custom);
    const { values: dataValues, setDataValues } = useContext(AppContext);

    const [datesList, setDatesList] = useState<DateItem[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateItem>({
        day: new Date().getDay(),
        date: new Date().getDate(),
        fullDate: new Date(),
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
    });
    const [isAppointmentBooked, setIsAppointmentBooked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState<TimeObject>({});
    const [selectedTimezone, setSelectedTimezone] = useState(
        "GMT Standard Time" // "British Summer Time"
    );
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [currentView, setCurrentView] = useState("calendar");
    const [eventsList, setEventsList] = useState<CalendarEvent[]>([]);
    const [leadSourceType, setAppointmentType] = useState("Video Call");
    const daysInWeek = [
        "sunday",
        "monday",
        "teusday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ];
    const [modifiedTimes, setModifiedTimes] = useState(
        getTimes(theme?.custom, daysInWeek[new Date().getDay()])
    );

    const scrollbarStyle = {
        "&::-webkit-scrollbar-thumb": {
            background: `${theme.palette.primary.main} !important`,
            "&:hover": {
                background: `${theme.palette.primary.main} !important`,
            },
        },
    };

    const isCurrentDateTimeDisabled = (timeToCheck: string) => {
        //getTimes(theme?.custom);
        const timezoneObj = getTimezone(selectedTimezone);
        const timezoneDateTimeString = new Date()
            .toLocaleString("en-US", {
                timeZone: timezoneObj?.utc[0],
                hour12: false,
            })
            .split(",");
        const timezoneDate = timezoneDateTimeString[0].trim();
        const timezoneTime = timezoneDateTimeString[1].trim();
        const month = parseInt(timezoneDate.split("/")[0]);
        const date = parseInt(timezoneDate.split("/")[1]);

        //today
        if (date === selectedDate?.date && month === selectedDate?.month + 1) {
            const hours = timeToCheck.split(":")[0];
            const minutes = timeToCheck.split(":")[1];
            const currentHours = timezoneTime.split(":")[0];
            const currentMinutes = timezoneTime.split(":")[1];

            if (hours === currentHours) {
                return minutes <= currentMinutes;
            }

            return hours <= currentHours;
        }

        return false;
    };

    const isTimeDisabled = (time: string) => {
        const timezoneObj = getTimezone(selectedTimezone);
        const timezone = `${
            Math.sign(timezoneObj?.offset!) === -1 ? "-" : "+"
        }${("0" + Math.abs(timezoneObj?.offset!)).slice(-2)}00`;
        const date = ("0" + selectedDate?.date).slice(-2);
        const month = ("0" + (selectedDate?.month + 1)).slice(-2);
        const fullDate = `${selectedDate?.year}-${month}-${date}`;
        const startDate = new Date(new Date(`${fullDate}T${time}${timezone}`));
        const endDate = new Date(new Date(`${fullDate}T${time}${timezone}`));
        endDate.setMinutes(
            endDate.getMinutes() +
                60 * (theme?.custom?.workHours?.timeInterval || 0.5)
        );
        const utcDate = startDate.toISOString();
        const utcEndDate = endDate.toISOString();
        const utcTime = utcDate.split("T")[1].split(":");
        const utcEndTime = utcEndDate.split("T")[1].split(":");
        const formattedDate = `${utcDate.split("T")[0]}T${utcTime[0]}:${
            utcTime[1]
        }`;
        const formattedEndDate = `${utcEndDate.split("T")[0]}T${
            utcEndTime[0]
        }:${utcEndTime[1]}`;
        const filteredEvents = eventsList?.find(
            (event) =>
                new Date(formattedEndDate) > new Date(event.startTime) &&
                new Date(formattedDate) < new Date(event.endTime)
        );

        return (
            isCurrentDateTimeDisabled(time) ||
            filteredEvents !== undefined ||
            isDateBlocked()
        );
    };

    const isDateBlocked = () => {
        const formattedSelectedDate = `${selectedDate.year}-${(
            "0" +
            (selectedDate.month + 1)
        ).slice(-2)}-${`${("0" + selectedDate.date).slice(-2)}`}`;

        return theme?.custom?.workHours?.blockDates?.includes(
            formattedSelectedDate
        );
    };

    const getEvents = useCallback(async (dateObj: DateItem = selectedDate) => {
        if (
            theme?.custom?.apiKey &&
            !theme?.custom?.appointmentCustomization?.disableLinking
        ) {
            setIsLoading(true);

            const date = ("0" + dateObj?.date).slice(-2);
            const month = ("0" + (dateObj?.month + 1)).slice(-2);
            const fullDate = `${dateObj?.year}-${month}-${date}`;
            const {
                data: { events },
            } = await axios.post(`${REACT_APP_MORTGAGE_URL}/api/teams/events`, {
                apiKey: theme?.custom?.apiKey,
                startTime: fullDate,
                endTime: fullDate,
            });

            setEventsList(events || []);

            setIsLoading(false);
        }
    }, []);

    const handleSubmit = async (
        values: CalendarConfirmation,
        formik: FormikHelpers<CalendarConfirmation>
    ) => {
        if (!isValidPhoneNumber(values.phoneNumber!)) {
            formik.setFieldError("phoneNumber", "Phone Number is invalid");
        } else {
            try {
                setIsLoading(true);

                const name = `${values?.firstName} ${values?.lastName}`;
                const timezoneObj = getTimezone(selectedTimezone);
                const date = ("0" + selectedDate?.date).slice(-2);
                const month = ("0" + (selectedDate?.month + 1)).slice(-2);
                const timezone = `${
                    Math.sign(timezoneObj?.offset!) === -1 ? "-" : "+"
                }${"0" + Math.abs(timezoneObj?.offset!)}00`;
                const startTime = `${selectedDate?.year}-${month}-${date}T${selectedTime?.time}${timezone}`;
                const endTime = `${selectedDate?.year}-${month}-${date}T${selectedTime?.end}${timezone}`;
                const _portalName = theme?.emailCustomization?.portalName || "";
                const formData = Object.keys(dataValues)
                    .map((key) => {
                      if (
                        key === "theme" ||
                        key === "simplified" ||
                        key === "templates" ||
                        key === "property_filtered"
                      ) {
                        return null;
                      }
                
                      if (
                        key === "mortgage_type" &&
                        (theme?.version === "customer-service-questionnaire" ||
                          theme?.version === "rightmove")
                      ) {
                        return null;
                      }
                
                      if (
                        _portalName === "Fair Investment App" &&
                        (key === "payment_method" ||
                          key === "reasonForRemortgage" ||
                          key === "product_type" ||
                          key === "product_term" ||
                          key === "mortgage_class" ||
                          key === "monthly_rent")
                      ) {
                        return null;
                      }
                
                      let refinedKey = key;
                      if (key.indexOf("]_") > -1) {
                        refinedKey = key.substring(key.indexOf("]_") + 2);
                      }
                      return {
                        label: _.startCase(refinedKey),
                        value: JSON.stringify(dataValues[key]),
                      };
                    })
                    .filter(Boolean);

                if (theme?.custom?.apiKey !== "") {
                    if (
                        theme?.custom?.appointmentCustomization
                            ?.enableAppointmentType === true
                    ) {
                        if (leadSourceType === "Video Call") {
                            await axios.post(
                                `${REACT_APP_MORTGAGE_URL}/api/teams/scheduleAppointment`,
                                {
                                    apiKey: theme?.custom?.apiKey,
                                    name,
                                    email: values?.email,
                                    startTime,
                                    endTime,
                                    props: {
                                        startTime: `${
                                            months[selectedDate?.month]
                                        } ${selectedDate?.date}, ${
                                            selectedDate?.year
                                        } at ${selectedTime?.label}`,
                                        email: values?.email,
                                        name,
                                        formData: [
                                            ...formData,
                                            {
                                                label: "Name",
                                                value: name,
                                            },
                                            {
                                                label: "Email",
                                                value: values?.email,
                                            },
                                            {
                                                label: "Phone Number",
                                                value: values?.phoneNumber,
                                            },
                                            {
                                                label: "Start Date",
                                                value: startTime,
                                            },
                                            {
                                                label: "End Date",
                                                value: endTime,
                                            },
                                            theme?.custom
                                                ?.appointmentCustomization
                                                ?.enableNotesSection
                                                ? {
                                                      label: "Notes",
                                                      value: values?.notes,
                                                  }
                                                : false,
                                            {
                                                label: "Timezone",
                                                value: `${timezoneObj?.text}`,
                                            },
                                            {
                                                label: "Lead Source Type",
                                                value: `${leadSourceType}`,
                                            },
                                        ].filter(Boolean),
                                        companyLogo: theme?.custom?.logo,
                                        companyName:
                                            theme?.custom?.customization
                                                ?.companyName || "Twenty7tec",
                                        theme: theme?.custom?.customization
                                            ?.primaryColor,
                                        botName:
                                            theme?.custom?.customization
                                                ?.botName,
                                        leadSourceType,
                                    },
                                    templateName: theme?.custom
                                        ?.emailCustomization
                                        ?.appointmentTemplate
                                        ? theme?.custom?.emailCustomization
                                              ?.appointmentTemplate
                                        : theme?.custom.appointmentCustomization
                                              ?.appointmentTemplate
                                        ? theme?.custom.appointmentCustomization
                                              ?.appointmentTemplate
                                        : "video-appointment",
                                    emailCustomization: {
                                        ...theme?.custom?.emailCustomization,
                                        subject:
                                            theme?.custom?.emailCustomization
                                                ?.subject ||
                                            "Mortgage Appointment",
                                        cc:
                                            theme?.custom?.emailCustomization
                                                ?.cc || null,
                                    },
                                    appointmentCustomization:
                                        theme?.custom?.appointmentCustomization,
                                    admin: theme?.custom?.email,
                                    links: theme?.custom?.links,
                                }
                            );
                        } else {
                            const defaultTemplate =
                                leadSourceType === "Telephone Call"
                                    ? "call-appointment"
                                    : "face-to-face-appointment";
                            await axios.post(
                                `${REACT_APP_MORTGAGE_URL}/api/teams/requestCallOrFaceToFaceAppointment`,
                                {
                                    apiKey: theme?.custom?.apiKey,
                                    name,
                                    email: values?.email,
                                    startTime,
                                    endTime,
                                    props: {
                                        startTime: `${
                                            months[selectedDate?.month]
                                        } ${selectedDate?.date}, ${
                                            selectedDate?.year
                                        } at ${selectedTime?.label}`,
                                        email: values?.email,
                                        name,
                                        formData: [
                                            ...formData,
                                            {
                                                label: "Name",
                                                value: name,
                                            },
                                            {
                                                label: "Email",
                                                value: values?.email,
                                            },
                                            {
                                                label: "Phone Number",
                                                value: values?.phoneNumber,
                                            },
                                            {
                                                label: "Start Date",
                                                value: startTime,
                                            },
                                            {
                                                label: "End Date",
                                                value: endTime,
                                            },
                                            theme?.custom
                                                ?.appointmentCustomization
                                                ?.enableNotesSection
                                                ? {
                                                      label: "Notes",
                                                      value: values?.notes,
                                                  }
                                                : false,
                                            {
                                                label: "Timezone",
                                                value: `${timezoneObj?.text}`,
                                            },
                                            {
                                                label: "Lead Source Type",
                                                value: `${leadSourceType}`,
                                            },
                                        ].filter(Boolean),
                                        companyLogo: theme?.custom?.logo,
                                        companyName:
                                            theme?.custom?.customization
                                                ?.companyName || "Twenty7tec",
                                        theme: theme?.custom?.customization
                                            ?.primaryColor,
                                        botName:
                                            theme?.custom?.customization
                                                ?.botName,
                                        leadSourceType,
                                    },
                                    templateName: theme?.custom
                                        ?.emailCustomization
                                        ?.appointmentTemplate
                                        ? theme?.custom?.emailCustomization
                                              ?.appointmentTemplate
                                        : theme?.custom.appointmentCustomization
                                              ?.appointmentTemplate
                                        ? theme?.custom.appointmentCustomization
                                              ?.appointmentTemplate
                                        : defaultTemplate,
                                    emailCustomization: {
                                        ...theme?.custom?.emailCustomization,
                                        subject:
                                            theme?.custom?.emailCustomization
                                                ?.subject ||
                                            "Mortgage Appointment",
                                        cc:
                                            theme?.custom?.emailCustomization
                                                ?.cc || null,
                                    },
                                    appointmentCustomization:
                                        theme?.custom?.appointmentCustomization,
                                    admin: theme?.custom?.email,
                                    links: theme?.custom?.links,
                                }
                            );
                        }
                    } else {
                        await axios.post(
                            `${REACT_APP_MORTGAGE_URL}/api/teams/scheduleAppointment`,
                            {
                                apiKey: theme?.custom?.apiKey,
                                name,
                                email: values?.email,
                                startTime,
                                endTime,
                                props: {
                                    startTime: `${
                                        months[selectedDate?.month]
                                    } ${selectedDate?.date}, ${
                                        selectedDate?.year
                                    } at ${selectedTime?.label}`,
                                    email: values?.email,
                                    name,
                                    formData: [
                                        ...formData,
                                        {
                                            label: "Name",
                                            value: name,
                                        },
                                        {
                                            label: "Email",
                                            value: values?.email,
                                        },
                                        {
                                            label: "Phone Number",
                                            value: values?.phoneNumber,
                                        },
                                        {
                                            label: "Start Date",
                                            value: startTime,
                                        },
                                        {
                                            label: "End Date",
                                            value: endTime,
                                        },
                                        theme?.custom?.appointmentCustomization
                                            ?.enableNotesSection
                                            ? {
                                                  label: "Notes",
                                                  value: values?.notes,
                                              }
                                            : false,
                                        {
                                            label: "Timezone",
                                            value: `${timezoneObj?.text}`,
                                        },
                                    ].filter(Boolean),
                                    companyLogo: theme?.custom?.logo,
                                    companyName:
                                        theme?.custom?.customization
                                            ?.companyName || "Twenty7tec",
                                    theme: theme?.custom?.customization
                                        ?.primaryColor,
                                    botName:
                                        theme?.custom?.customization?.botName,
                                },
                                templateName: theme?.custom?.emailCustomization
                                    ?.appointmentTemplate
                                    ? theme?.custom?.emailCustomization
                                          ?.appointmentTemplate
                                    : theme?.custom.appointmentCustomization
                                          ?.appointmentTemplate
                                    ? theme?.custom.appointmentCustomization
                                          ?.appointmentTemplate
                                    : "video-appointment",
                                emailCustomization: {
                                    ...theme?.custom?.emailCustomization,
                                    subject:
                                        theme?.custom?.emailCustomization
                                            ?.subject || "Mortgage Appointment",
                                    cc:
                                        theme?.custom?.emailCustomization?.cc ||
                                        null,
                                },
                                appointmentCustomization:
                                    theme?.custom?.appointmentCustomization,
                                admin: theme?.custom?.email,
                                links: theme?.custom?.links,
                            }
                        );
                    }

                    trackPluginEvent({
                        apiKey: theme?.custom?.apiKey,
                        appointment: {
                            type: leadSourceType,
                            date: startTime,
                        },
                        lead: values,
                    });

                    if (theme?.custom?.googleAnalyticsTrackingId?.length) {
                        await trackGAEvent(
                            theme?.custom?.googleAnalyticsTrackingId!,
                            {
                                category: "MeetParkerChat",
                                action: "Book Appointment",
                            }
                        );
                    }
                    if (
                        theme?.custom?.apiKey ===
                        "e2682a60-68e5-4c93-b7a8-91ed8a99b8d4"
                    ) {
                        //@ts-ignore
                        window.dataLayer = window.dataLayer || [];
                        //@ts-ignore
                        window.dataLayer.push({
                            event: "mortgage_appointment",
                            action: "booking_complete",
                            form_type: "main",
                        });

                        console.log(`//@ts-ignore
                        window.dataLayer = window.dataLayer || [];
                        //@ts-ignore
                        window.dataLayer.push({
                            event: "mortgage_appointment",
                            action: "booking_complete",
                            form_type: "main",
                        });`);

                        console.log(
                            `window.dataLayer: `,
                            //@ts-ignore
                            window.dataLayer
                        );
                    }
                }

                setIsAppointmentBooked(true);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getTimezone = (value: string) => {
        return timezones.find((timezone) => timezone.value === value);
    };

    const isSelected = (date: DateItem) => {
        if (!date) return false;

        return (
            selectedDate.date === date.date &&
            selectedDate.month === date.month &&
            selectedDate.year === date.year
        );
    };

    const isGreaterThanToday = (date: Date | null) => {
        if (!date) return false;

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        return date >= today;
    };

    const getDatesInMonth = (year: number, month: number) => {
        const dates = [];
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDay =
            new Date(year, month, 1).getDay() === 0
                ? 6
                : new Date(year, month, 1).getDay() - 1;
        const lastDay = new Date(year, month + 1, 0).getDay() - 1;

        for (let i = 0; i < firstDay; i++) {
            dates.push({
                date: -1,
                day: -1,
                fullDate: null,
                month,
                year,
            });
        }

        for (let i = 0; i < daysInMonth; i++) {
            dates.push({
                date: i + 1,
                day: new Date(year, month, i + 1).getDay(),
                fullDate: new Date(year, month, i + 1),
                month,
                year,
            });
        }

        for (let i = 0; i < 6 - lastDay; i++) {
            dates.push({
                date: -1,
                day: -1,
                fullDate: null,
                month,
                year,
            });
        }

        setDatesList(dates);
    };

    const renderCalendar = () => {
        return (
            <>
                <div className="calendar-title">Select a Date & Time</div>
                <div className="calendar-container">
                    <div className="calendar-left">
                        <div className="calendar-header">
                            <div className="calendar-datepicker">
                                <div className="calendar-left-panel">
                                    <button className="calendar-datepicker-month">
                                        {months[selectedMonth]}
                                    </button>
                                    <button className="calendar-datepicker-year">
                                        {selectedYear}
                                    </button>
                                </div>
                                <div className="calendar-right-panel">
                                    <button
                                        className="calendar-datepicker-decrease-month"
                                        onClick={() => {
                                            const previousSelectedDate =
                                                new Date(
                                                    selectedYear,
                                                    selectedMonth,
                                                    1
                                                );
                                            previousSelectedDate.setMonth(
                                                previousSelectedDate.getMonth() -
                                                    1
                                            );
                                            setSelectedYear(
                                                previousSelectedDate.getFullYear()
                                            );
                                            setSelectedMonth(
                                                previousSelectedDate.getMonth()
                                            );
                                            //getDatesInMonth(previousSelectedDate.getFullYear(), previousSelectedDate.getMonth())
                                        }}
                                    >
                                        {/* <ChevronLeft color="#9C9C9C" /> */}
                                        <img
                                            src={ChevronLeft}
                                            alt="ChevronLeft"
                                        />
                                    </button>
                                    <button
                                        className="calendar-datepicker-increase-month"
                                        onClick={() => {
                                            const nextSelectedDate = new Date(
                                                selectedYear,
                                                selectedMonth,
                                                1
                                            );
                                            nextSelectedDate.setMonth(
                                                nextSelectedDate.getMonth() + 1
                                            );
                                            setSelectedYear(
                                                nextSelectedDate.getFullYear()
                                            );
                                            setSelectedMonth(
                                                nextSelectedDate.getMonth()
                                            );
                                            //getDatesInMonth(nextSelectedDate.getFullYear(), nextSelectedDate.getMonth())
                                        }}
                                    >
                                        <img
                                            src={ChevronRight}
                                            alt="ChevronRight"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="calendar-dates">
                            <div className="calendar-dates-header">
                                <div className="calendar-dates-day-name">
                                    MON
                                </div>
                                <div className="calendar-dates-day-name">
                                    TUE
                                </div>
                                <div className="calendar-dates-day-name">
                                    WED
                                </div>
                                <div className="calendar-dates-day-name">
                                    THU
                                </div>
                                <div className="calendar-dates-day-name">
                                    FRI
                                </div>
                                <div className="calendar-dates-day-name">
                                    SAT
                                </div>
                                <div className="calendar-dates-day-name">
                                    SUN
                                </div>
                            </div>
                            <div className="calendar-dates-days">
                                {datesList.map((dateItem, index) => (
                                    <Box
                                        key={index}
                                        className={`calendar-dates-day 
                    ${
                        (isGreaterThanToday(dateItem.fullDate) &&
                            dateItem.day !== 0 &&
                            dateItem.day !== 6) ||
                        (theme?.custom?.workHours?.days?.saturday &&
                            dateItem.day === 6) ||
                        (theme?.custom?.workHours?.days?.sunday &&
                            dateItem.day === 0)
                            ? "selectable"
                            : ""
                    }
                    ${isSelected(dateItem) ? "selected" : ""}`}
                                        onClick={() => {
                                            console.log(
                                                "dateItem ",
                                                dateItem.day
                                            );
                                            setSelectedDate(dateItem);
                                            setModifiedTimes(
                                                getTimes(
                                                    theme?.custom,
                                                    daysInWeek[dateItem.day]
                                                )
                                            );
                                            getEvents(dateItem);
                                        }}
                                        sx={{
                                            ...(isGreaterThanToday(
                                                dateItem.fullDate
                                            ) &&
                                                ((dateItem.day !== 0 &&
                                                    dateItem.day !== 6) ||
                                                    (theme?.custom?.workHours
                                                        ?.days?.saturday &&
                                                        dateItem.day === 6) ||
                                                    (theme?.custom?.workHours
                                                        ?.days?.sunday &&
                                                        dateItem.day ===
                                                            0)) && {
                                                    backgroundColor: alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.1
                                                    ),
                                                    color: `${theme?.palette?.primary?.main} !important`,
                                                }),
                                            ...(isSelected(dateItem) && {
                                                backgroundColor: `${theme?.palette?.primary?.main} !important`,
                                                color: "#FFF",
                                            }),
                                        }}
                                    >
                                        {dateItem.date === -1
                                            ? ""
                                            : dateItem.date}
                                    </Box>
                                ))}
                            </div>
                        </div>
                        <div className="calendar-timezone">
                            <label>Timezone</label>
                            <div className="timezone">
                                <img
                                    width="24"
                                    src="https://d365pq86x330zn.cloudfront.net/9o1l696o-4072-4992-4l91-912l6613223l.png"
                                    alt="clock"
                                />
                                <label>{selectedTimezone}</label>
                                <img
                                    width="12"
                                    src="https://d365pq86x330zn.cloudfront.net/97174341-1073-4617-6e91-66e71021e069.png"
                                    alt="chevrondown"
                                />
                                <select
                                    value={selectedTimezone}
                                    onChange={(event) =>
                                        setSelectedTimezone(event.target.value)
                                    }
                                >
                                    {timezones.map((timezone, index) => (
                                        <option
                                            key={index}
                                            value={timezone.value}
                                        >
                                            {timezone.text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Box className="calendar-right" sx={scrollbarStyle}>
                        <Box className="current-selected-date">
                            {dayNames[selectedDate.day]},{" "}
                            {months[selectedDate.month]} {selectedDate.date}
                        </Box>
                        {theme?.custom?.workHours?.hours
                            ? modifiedTimes.map((time, index) =>
                                  selectedTime?.time === time.time ? (
                                      <div key={time.time} className="selected">
                                          <button className="selected-time">
                                              {time.label}
                                          </button>
                                          <Button
                                              variant="outlined"
                                              color="primary"
                                              sx={{
                                                  border: `1px solid ${theme?.palette?.primary?.main} !important`,
                                                  backgroundColor: `${theme?.palette?.primary?.main} !important`,
                                                  textTransform: "capitalize",
                                              }}
                                              onClick={() => {
                                                  setCurrentView(
                                                      "confirmation"
                                                  );
                                              }}
                                              key={index}
                                              className="confirm-btn"
                                          >
                                              Confirm
                                          </Button>
                                      </div>
                                  ) : isLoading ? null : (
                                      <Button
                                          variant="outlined"
                                          color="primary"
                                          disabled={isTimeDisabled(time?.time)}
                                          sx={{
                                              "&:hover": {
                                                  backgroundColor: `${theme?.palette?.primary?.main} !important`,
                                              },
                                          }}
                                          key={index}
                                          className="time"
                                          onClick={() => setSelectedTime(time)}
                                      >
                                          {time.label}
                                      </Button>
                                  )
                              )
                            : times.map((time, index) =>
                                  selectedTime?.time === time.time ? (
                                      <div key={time.time} className="selected">
                                          <button className="selected-time">
                                              {time.label}
                                          </button>
                                          <Button
                                              variant="outlined"
                                              color="primary"
                                              sx={{
                                                  border: `1px solid ${theme?.palette?.primary?.main} !important`,
                                                  backgroundColor: `${theme?.palette?.primary?.main} !important`,
                                                  textTransform: "capitalize",
                                              }}
                                              onClick={() => {
                                                  setCurrentView(
                                                      "confirmation"
                                                  );
                                              }}
                                              key={index}
                                              className="confirm-btn"
                                          >
                                              Confirm
                                          </Button>
                                      </div>
                                  ) : isLoading ? null : (
                                      <Button
                                          variant="outlined"
                                          color="primary"
                                          disabled={isTimeDisabled(time?.time)}
                                          sx={{
                                              "&:hover": {
                                                  backgroundColor: `${theme?.palette?.primary?.main} !important`,
                                              },
                                          }}
                                          key={index}
                                          className="time"
                                          onClick={() => setSelectedTime(time)}
                                      >
                                          {time.label}
                                      </Button>
                                  )
                              )}
                    </Box>
                </div>
            </>
        );
    };

    const renderBookingConfirmation = () => {
        return (
            <Formik
                initialValues={{
                    email: dataValues?.applicant1_email|| "",
                    firstName: dataValues?.applicant1_firstName || "",
                    lastName: dataValues?.applicant1_lastName || "",
                    phoneNumber: dataValues?.applicant1_contactNumber || "",
                    ...(theme?.custom?.appointmentCustomization
                        ?.enableNotesSection
                        ? {
                              notes: "",
                          }
                        : {}),
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email("Must be a valid email")
                        .max(255)
                        .required("Email is required"),
                    firstName: Yup.string()
                        .max(255)
                        .required("First Name is required"),
                    lastName: Yup.string()
                        .max(255)
                        .required("Last Name is required"),
                    phoneNumber: Yup.string().required(
                        "Phone Number is required"
                    ),
                    ...(theme?.custom?.appointmentCustomization
                        ?.enableNotesSection
                        ? {
                              notes: Yup.string().max(255),
                          }
                        : {}),
                })}
                onSubmit={handleSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <Grid
                        container
                        spacing={2}
                        className="confirmation-container"
                    >
                        <Grid item xs={12} md={6}>
                            {theme?.custom?.logo ? (
                                <img
                                    src={theme?.custom?.logo}
                                    alt={
                                        theme?.custom?.customization
                                            ?.companyName || "MeetParker"
                                    }
                                    height="auto"
                                    width="200"
                                />
                            ) : null}
                            <Typography
                                fontSize={32}
                                fontWeight={700}
                                color="primary"
                                lineHeight="39.65px"
                                marginBottom="27px"
                                marginTop="41px"
                            >
                                {theme?.custom?.emailCustomization?.subject ||
                                    "Mortgage Appointment"}
                            </Typography>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                alignItems: 'center',
                                gap: '14px'
                            }}>
                                <div>
                                    <img
                                        src={ClockIcon}
                                        alt="ChevronRight"
                                        style={{ verticalAlign: "middle" }}
                                    />
                                </div>
                                <Typography
                                    fontSize={18}
                                    fontWeight={600}
                                    lineHeight="19.82px"
                                >
                                    {60 *
                                        (theme?.custom?.workHours?.timeInterval ||
                                            0.5)}{" "}
                                    minutes
                                </Typography>
                                <div>
                                    <img
                                        src={CalendarIcon}
                                        alt="ChevronRight"
                                        style={{ verticalAlign: "middle" }}
                                    />
                                </div>
                                <Typography
                                    fontSize={18}
                                    fontWeight={600}
                                    lineHeight="19.82px"
                                >
                                    {selectedTime?.label},{" "}
                                    {dayNames[selectedDate?.day]}{" "}
                                    {months[selectedDate?.month]}{" "}
                                    {selectedDate?.date}, {selectedDate?.year}
                                </Typography>
                                <div>
                                    <img
                                        src={WorldIcon}
                                        alt="ChevronRight"
                                        style={{ verticalAlign: "middle" }}
                                    />
                                </div>
                                <Typography
                                    fontSize={18}
                                    fontWeight={600}
                                    lineHeight="19.82px"
                                >
                                    {selectedTimezone}
                                </Typography>
                            </div>
                            
                            <Grid
                                container
                                spacing={2}
                                className="confirmation-container"
                                minWidth="lg"
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        fontSize={28}
                                        fontWeight={700}
                                        color="primary"
                                        lineHeight="39.65px"
                                        marginBottom="27px"
                                        marginTop="41px"
                                    >
                                        Appointment Type
                                    </Typography>
                                    {theme?.custom?.appointmentCustomization
                                        ?.disableVideoCall ===
                                    true ? null : (
                                        <Typography
                                            fontSize={18}
                                            fontWeight={600}
                                            lineHeight="19.82px"
                                            marginTop="14px"
                                        >
                                            <svg
                                                className="svg"
                                                fill="currentColor"
                                                preserveAspectRatio="xMidYMid meet"
                                                height="30"
                                                width="30"
                                                viewBox="0 0 34 34"
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                                onClick={() =>
                                                    setAppointmentType(
                                                        "Video Call"
                                                    )
                                                }
                                            >
                                                <circle
                                                    cx="17"
                                                    cy="17"
                                                    r="15"
                                                    fill="none"
                                                    stroke="black"
                                                    strokeWidth="3"
                                                />
                                                {leadSourceType ===
                                                "Video Call" ? (
                                                    <circle
                                                        cx="17"
                                                        cy="17"
                                                        r="8"
                                                        fill={
                                                            theme?.palette
                                                                ?.primary
                                                                ?.main
                                                        }
                                                    />
                                                ) : null}
                                            </svg>
                                            <span
                                                onClick={() =>
                                                    setAppointmentType(
                                                        "Video Call"
                                                    )
                                                }
                                            >
                                                &nbsp;&nbsp;Video Call
                                            </span>
                                        </Typography>
                                    )}
                                    {theme?.custom?.appointmentCustomization
                                        ?.disableTelephoneCall ===
                                    true ? null : (
                                        <Typography
                                            fontSize={18}
                                            fontWeight={600}
                                            lineHeight="19.82px"
                                            marginTop="14px"
                                        >
                                            <svg
                                                className="svg"
                                                fill="currentColor"
                                                preserveAspectRatio="xMidYMid meet"
                                                height="30"
                                                width="30"
                                                viewBox="0 0 34 34"
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                                onClick={() =>
                                                    setAppointmentType(
                                                        "Telephone Call"
                                                    )
                                                }
                                            >
                                                <circle
                                                    cx="17"
                                                    cy="17"
                                                    r="15"
                                                    fill="none"
                                                    stroke="black"
                                                    strokeWidth="3"
                                                />
                                                {leadSourceType ===
                                                "Telephone Call" ? (
                                                    <circle
                                                        cx="17"
                                                        cy="17"
                                                        r="8"
                                                        fill={
                                                            theme?.palette
                                                                ?.primary
                                                                ?.main
                                                        }
                                                    />
                                                ) : null}
                                            </svg>
                                            <span
                                                onClick={() =>
                                                    setAppointmentType(
                                                        "Telephone Call"
                                                    )
                                                }
                                            >
                                                &nbsp;&nbsp;Telephone Call
                                            </span>
                                        </Typography>
                                    )}
                                    {theme?.custom?.appointmentCustomization
                                        ?.disableFaceToFaceMeeting ===
                                    true ? null : (
                                        <Typography
                                            fontSize={18}
                                            fontWeight={600}
                                            lineHeight="19.82px"
                                            marginTop="14px"
                                        >
                                            <svg
                                                className="svg"
                                                fill="currentColor"
                                                preserveAspectRatio="xMidYMid meet"
                                                height="30"
                                                width="30"
                                                viewBox="0 0 34 34"
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                                onClick={() =>
                                                    setAppointmentType(
                                                        "Face to Face Appointment"
                                                    )
                                                }
                                            >
                                                <circle
                                                    cx="17"
                                                    cy="17"
                                                    r="15"
                                                    fill="none"
                                                    stroke="black"
                                                    strokeWidth="3"
                                                />
                                                {leadSourceType ===
                                                "Face to Face Appointment" ? (
                                                    <circle
                                                        cx="17"
                                                        cy="17"
                                                        r="8"
                                                        fill={
                                                            theme?.palette
                                                                ?.primary
                                                                ?.main
                                                        }
                                                    />
                                                ) : null}
                                            </svg>
                                            <span
                                                onClick={() =>
                                                    setAppointmentType(
                                                        "Face to Face Appointment"
                                                    )
                                                }
                                            >
                                                &nbsp;&nbsp;Face to Face
                                                Meeting
                                            </span>
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            
                            {theme?.custom?.appointmentCustomization
                                ?.enableNotesSection === true ? (
                                <Grid item xs={12} marginTop="24px">
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="notes">
                                            <Typography
                                                fontSize={16}
                                                fontWeight={700}
                                                color="primary"
                                            >
                                                Notes
                                            </Typography>
                                        </InputLabel>
                                        <TextField
                                            id="notes"
                                            className="input-field"
                                            type="text"
                                            variant="outlined"
                                            value={values.notes}
                                            name="notes"
                                            multiline
                                            rows={5}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={Boolean(
                                                touched.notes && errors.notes
                                            )}
                                        />
                                        {touched.notes && errors.notes && (
                                            <FormHelperText
                                                error
                                                id="standard-weight-helper-text-notes-login"
                                            >
                                                <Typography
                                                    component="span"
                                                    fontWeight={600}
                                                    fontSize={14}
                                                >
                                                    {errors.notes}
                                                </Typography>
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                color="primary"
                                fontSize={24}
                                fontWeight={600}
                                lineHeight="29.74px"
                                marginBottom="20px"
                                fontFamily="'Montserrat', sans-serif"
                            >
                                Enter Details:
                            </Typography>
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="firstName-login">
                                                <Typography
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    lineHeight="19.82px"
                                                    color="#292929"
                                                    marginBottom="15px"
                                                >
                                                    First Name
                                                </Typography>
                                            </InputLabel>
                                            <TextField
                                                id="firstName-login"
                                                className="input-field"
                                                type="text"
                                                variant="outlined"
                                                value={values.firstName}
                                                name="firstName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={Boolean(
                                                    touched.firstName &&
                                                        errors.firstName
                                                )}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-firstName-login"
                                                >
                                                    <Typography
                                                        component="span"
                                                        fontWeight={600}
                                                        fontSize={14}
                                                    >
                                                        {errors.firstName}
                                                    </Typography>
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="lastName-login">
                                                <Typography
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    lineHeight="19.82px"
                                                    color="#292929"
                                                    marginBottom="15px"
                                                >
                                                    Last Name
                                                </Typography>
                                            </InputLabel>
                                            <TextField
                                                id="lastName-login"
                                                className="input-field"
                                                type="text"
                                                variant="outlined"
                                                value={values.lastName}
                                                name="lastName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={Boolean(
                                                    touched.lastName &&
                                                        errors.lastName
                                                )}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-lastName-login"
                                                >
                                                    <Typography
                                                        component="span"
                                                        fontWeight={600}
                                                        fontSize={14}
                                                    >
                                                        {errors.lastName}
                                                    </Typography>
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login">
                                                <Typography
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    lineHeight="19.82px"
                                                    color="#292929"
                                                    marginBottom="15px"
                                                >
                                                    Email
                                                </Typography>
                                            </InputLabel>
                                            <TextField
                                                id="email-login"
                                                className="input-field"
                                                type="email"
                                                variant="outlined"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={Boolean(
                                                    touched.email &&
                                                        errors.email
                                                )}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    <Typography
                                                        component="span"
                                                        fontWeight={600}
                                                        fontSize={14}
                                                    >
                                                        {errors.email}
                                                    </Typography>
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="phoneNumber-login">
                                                <Typography
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    lineHeight="19.82px"
                                                    color="#292929"
                                                    marginBottom="15px"
                                                >
                                                    Phone Number
                                                </Typography>
                                            </InputLabel>
                                            <PhoneInput
                                                id="phone-login"
                                                name="phoneNumber"
                                                international
                                                defaultCountry="GB"
                                                onChange={(value: any) =>
                                                    setFieldValue(
                                                        "phoneNumber",
                                                        value
                                                    )
                                                }
                                            />
                                            {touched.phoneNumber &&
                                                errors.phoneNumber && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-phoneNumber-login"
                                                    >
                                                        <Typography
                                                            component="span"
                                                            fontWeight={600}
                                                            fontSize={14}
                                                        >
                                                            {errors.phoneNumber}
                                                        </Typography>
                                                    </FormHelperText>
                                                )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={
                                                isLoading || isAppointmentBooked
                                            }
                                            disableElevation
                                            disableRipple
                                            sx={{
                                                borderRadius: "42px",
                                                padding: "13px 26px",
                                            }}
                                        >
                                            <Typography
                                                fontSize={16}
                                                fontWeight={700}
                                                textAlign="center"
                                                color="#FFF"
                                                textTransform="capitalize"
                                            >
                                                Confirm Appointment
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    {isAppointmentBooked ? (
                                        <Grid item xs={12}>
                                            <Typography
                                                color="primary"
                                                component="span"
                                                fontWeight={600}
                                                fontSize={14}
                                            >
                                                {theme?.custom
                                                    ?.appointmentCustomization
                                                    ?.calendarBookSuccessText ||
                                                    "Thank you. One of the team will be in touch to confirm your appointment shortly"}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        );
    };

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    useEffect(() => {
        getDatesInMonth(selectedYear, selectedMonth);
    }, [selectedYear, selectedMonth, getEvents]);

    useEffect(() => {
        if (
            theme?.custom?.appointmentCustomization?.disableTelephoneCall ===
                true &&
            theme?.custom?.appointmentCustomization?.disableVideoCall ===
                true &&
            theme?.custom?.appointmentCustomization
                ?.disableFaceToFaceMeeting === true
        ) {
            setAppointmentType("Video Call");
        } else if (
            theme?.custom?.appointmentCustomization?.disableTelephoneCall ===
                true &&
            theme?.custom?.appointmentCustomization
                ?.disableFaceToFaceMeeting === true
        ) {
            setAppointmentType("Video Call");
        } else if (
            theme?.custom?.appointmentCustomization?.disableTelephoneCall ===
                true &&
            theme?.custom?.appointmentCustomization?.disableVideoCall === true
        ) {
            setAppointmentType("Face to Face Appointment");
        } else if (
            theme?.custom?.appointmentCustomization?.disableVideoCall ===
                true &&
            theme?.custom?.appointmentCustomization
                ?.disableFaceToFaceMeeting === true
        ) {
            setAppointmentType("Telephone Call");
        }
    }, []);

    return (
        <div
            id="calendar"
            className={`calendar-card ${currentView}`}
            style={{
                maxWidth: "100%",
            }}
        >
            {currentView === "calendar"
                ? renderCalendar()
                : renderBookingConfirmation()}
        </div>
    );
};

export default Calendar;
