import axios from "axios";
import { AnalyticsState } from "../../interfaces/Analytics";

const { REACT_APP_SHOP_URL } = process.env;

export async function trackPluginEvent(data: AnalyticsState): Promise<void> {
  try {
    const currentSessionId = sessionStorage.getItem("sessionId");

    if (currentSessionId && currentSessionId !== "") {
      await axios.post(`${REACT_APP_SHOP_URL}/api/v1/shop/client/analytics`, {
        sessionId: currentSessionId,
        apiKey: data.apiKey,
        pluginType: "calendar",
        ...data,
      });
    }
  } catch (err) {
    console.error("Track Plugin Event Error: ", err);
    const message = (err as Error).message;
    throw new Error(message);
  }
}
